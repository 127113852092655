import { FC } from "react";
import Header from "../../Components/Header";

const Blocks: FC = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default Blocks;
