import { gql, useQuery, useSubscription } from "@apollo/client";
import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import moment from "moment";
import { FC, useEffect, useState } from "react";

const BLOCKS = gql`
  query Blocks {
    blocks {
      id
      hash
      number
      timestamp
      transactions {
        from {
          address
        }
        to {
          address
        }
      }
    }
  }
`;

const BLOCK_SUBSCRIPTION = gql`
  subscription OnNewBlock {
    newBlock {
      hash
      number
      timestamp
    }
  }
`;

const Container = styled(Box)`
  margin-left: 20px;
  margin-right: 10px;
  height: 420px;
  padding: 20px;
  overflow: hidden;

  &:hover {
    overflow: scroll;
    padding: 20px;
  }
`;

const LatestBlocks: FC = () => {
  const [blocks, setBlocks] = useState<{ number: number; timestamp: string }[]>(
    []
  );
  const { data: allBlocks, loading: allBlocksLoading } = useQuery(BLOCKS);
  const { data: dataBlocks } = useSubscription(BLOCK_SUBSCRIPTION);

  useEffect(() => {
    if (dataBlocks) {
      let newBlocks = [...blocks];
      if (blocks.length === 10) {
        newBlocks = blocks.slice(1);
      }
      setBlocks([...newBlocks, dataBlocks.newBlock]);
    }
  }, [dataBlocks]);

  useEffect(() => {
    if (!allBlocksLoading) {
      setBlocks([...allBlocks.blocks].reverse());
    }
  }, [allBlocks, allBlocksLoading]);

  return (
    <Container flex={1} borderWidth="1px" borderRadius="lg">
      {!allBlocksLoading &&
        [...blocks].reverse().map((block) => (
          <Flex
            key={Math.random()}
            alignItems={"center"}
            direction={"row"}
            style={{ height: 50 }}
          >
            <Box style={{ marginRight: 20 }}>Block#</Box>
            <Box flex={1} style={{ color: "#7e14dc" }}>
              <a href={`/#/blocks/${block.number}`}>{block.number}</a>
            </Box>
            <Box style={{ marginRight: 20, color: "#7e8385" }}>
              {moment(block.timestamp).fromNow()}
            </Box>
          </Flex>
        ))}
    </Container>
  );
};

export default LatestBlocks;
