import { Input } from "@chakra-ui/react";
import styled from "@emotion/styled";

const Search = styled(Input)`
  background-color: #8e2de5;
  height: 45px;
  padding: 0 20px;
  width: 50%;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff84;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }
`;

function Header() {
  return (
    <div
      style={{
        backgroundColor: "#7e14dc",
        height: 60,
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        color: "#fff",
      }}
    >
      <a href="/#/" style={{ width: 200, fontWeight: "bold" }}>
        Block Explorer
      </a>
      <Search
        variant="unstyled"
        placeholder="Search for address, block, transaction"
      />
    </div>
  );
}

export default Header;
