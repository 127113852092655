import { Box, Flex } from "@chakra-ui/react";
import moment from "moment";
import { FC } from "react";
import Header from "../../Components/Header";
import LatestBlocks from "./LatestBlocks";
import Transfers from "./Transfers";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: (number) => `${number} secs ago`,
    ss: "%ds ago",
    m: "1m ago",
    mm: "%dm ago",
    h: "1h ago",
    hh: "%dh ago",
    d: "1d ago",
    dd: "%dd ago",
    M: "a month ago",
    MM: "%d months ago",
    y: "a year ago",
    yy: "%d years ago",
  },
});

const Home: FC = () => {
  return (
    <div>
      <Header />
      <div style={{ margin: "0 20" }}>
        <Flex alignItems={"center"} direction={"row"} style={{ height: 60 }}>
          <Box style={{ marginLeft: 20 }} flex={1}>
            Latest blocks
          </Box>
          <Box style={{ marginLeft: 0 }} flex={1}>
            Transfers
          </Box>
        </Flex>
        <Flex direction={"row"}>
          <LatestBlocks />
          <Transfers />
        </Flex>
      </div>
    </div>
  );
};

export default Home;
