import { gql, useQuery, useSubscription } from "@apollo/client";
import { Box, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import moment from "moment";
import { useState, useEffect, FC } from "react";

const TRANSACTIONS = gql`
  query Transactions {
    transactions {
      from {
        address
      }
      to {
        address
      }
      value
      hash
      timestamp
      block {
        hash
        number
        timestamp
      }
    }
  }
`;

const TRANSACTION_SUBSCRIPTION = gql`
  subscription NewTransaction {
    newTransaction {
      from {
        address
      }
      to {
        address
      }
      value
      hash
      timestamp
      block {
        hash
        number
        timestamp
      }
    }
  }
`;

const Container = styled(Box)`
  margin-left: 10px;
  margin-right: 20px;
  height: 420px;
  padding: 20px;
  overflow: hidden;

  &:hover {
    overflow: scroll;
    padding: 20px;
    padding-right: 14px;
  }
`;

const TransactionHash = styled(Box)`
  width: 200px;
  white-space: nowrap;
  color: #7e14dc;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Address = styled(Box)`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const Transfers: FC = () => {
  const [transactions, setTransactions] = useState<
    {
      from: string;
      to: string;
      value: number;
      hash: string;
      timestamp: string;
      number: number;
    }[]
  >([]);

  const { data: allTransactions, loading: allTransactionsLoading } =
    useQuery(TRANSACTIONS);

  const { data: dataTransactions } = useSubscription(TRANSACTION_SUBSCRIPTION);

  useEffect(() => {
    if (!allTransactionsLoading) {
      setTransactions(
        [...allTransactions.transactions].reverse().map((tx) => ({
          ...tx,
          from: tx.from.address as string,
          to: tx.to.address as string,
        }))
      );
    }
  }, [allTransactions, allTransactionsLoading]);

  useEffect(() => {
    if (dataTransactions) {
      let newTransactions = [...transactions];
      if (transactions.length === 10) {
        newTransactions = transactions.slice(1);
      }
      setTransactions([
        ...newTransactions,
        {
          from: dataTransactions.newTransaction.from.address as string,
          to: dataTransactions.newTransaction.to.address as string,
          value: dataTransactions.newTransaction.value as number,
          hash: dataTransactions.newTransaction.hash as string,
          timestamp: dataTransactions.newTransaction.timestamp as string,
          number: dataTransactions.newTransaction.block.number as number,
        },
      ]);
    }
  }, [dataTransactions]);
  return (
    <Container flex={1} borderWidth="1px" borderRadius="lg">
      {!allTransactionsLoading &&
        [...transactions].reverse().map((transaction) => (
          <Flex
            key={Math.random()}
            alignItems={"center"}
            direction={"row"}
            style={{ height: 60 }}
          >
            <Box>
              <TransactionHash>
                <a href={`/#/transactions/${transaction.hash}`}>
                  {transaction.hash}
                </a>
              </TransactionHash>
              <Flex direction={"row"} style={{ color: "#7e8385" }}>
                <Address>{transaction.from}</Address>
                <Box
                  style={{
                    fontSize: 12,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  →
                </Box>
                <Address>{transaction.to}</Address>
              </Flex>
            </Box>
            <Box
              flex={1}
              style={{
                marginLeft: 10,
                marginRight: 20,
                color: "#7e8385",
              }}
            >
              {moment(transaction.timestamp).fromNow()}
            </Box>
            <Box
              flex={1}
              style={{
                textAlign: "right",
              }}
            >
              {transaction.value} HZC
            </Box>
          </Flex>
        ))}
    </Container>
  );
};

export default Transfers;
