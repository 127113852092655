import { FC } from "react";
import Header from "../../Components/Header";

const TransactionDetails: FC = () => {
  return <>
  <Header />
  </>;
};

export default TransactionDetails;
