import { HashRouter as Router, Route, Routes } from "react-router-dom";
import AccountDetails from "./Containers/AccountDetails";
import BlockDetails from "./Containers/BlockDetails";
import Blocks from "./Containers/Blocks";
import Home from "./Containers/Home";
import TransactionDetails from "./Containers/TransactionDetails";
import Transactions from "./Containers/Transactions";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blocks" element={<Blocks />} />
        <Route path="/blocks/:hash" element={<BlockDetails />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transactions/:hash" element={<TransactionDetails />} />
        <Route path="/account/:address" element={<AccountDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
